import * as React from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import HomeSlider from '../../components/homeSlider/HomeSlider';
import ExtendedContent from '../../components/extendedContent';
import SEO from '../../components/seo/seo';
import Intro from '../../components/intro/Intro';
import BackgroundChange from '../../components/backgroundChange/BackgroundChange';
import getModulesWithBgChange from '../../utils/getModulesWithBgChange';
import isObjectEmpty from '../../utils/isObjectEmpty';

const IndexPage = ({ data: { homepageData, globalSliderHome, globalSeo, seoHome }, isIntroRendered }) => {
	const homeSliderModule = homepageData?.nodes?.[0]?.modulHomeSlider || [];
	const globalSliderHomeButtons = globalSliderHome?.nodes?.[0] || [];
	const introTextData = homepageData?.nodes?.[0]?.introTekst?.[0]?.url || '';
	const introAnimationData = homepageData?.nodes?.[0]?.introAnimacja?.[0]?.url || '';
	const allModules = homepageData?.nodes?.[0]?.wybierzModul || '';
	const globalSeoData = globalSeo?.nodes?.[0] || [];
	const seoHomeData = seoHome?.nodes?.[0] || [];
	const arrayForBgChanges = [];

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'auto' });
	}, []);

	const addToArrayForBgChanges = (moduleToBgChange) => {
		if (!isObjectEmpty(moduleToBgChange)) {
			arrayForBgChanges.push(moduleToBgChange);
		}
	};

	if (allModules) {
		allModules.forEach((module) => {
			if (module.__typename === 'Craft_moduly_modulWizytowkiAktualnosci_Entry') {
				const moduleDataArray = module?.modulWizytowkiAktualnosci || [];
				const moduleToBgChange = getModulesWithBgChange(module, moduleDataArray);
				addToArrayForBgChanges(moduleToBgChange);
			} else if (module.__typename === 'Craft_moduly_modulProcesPracy_Entry') {
				const moduleDataArray = module?.modulProcesPracy || [];
				const moduleToBgChange = getModulesWithBgChange(module, moduleDataArray);
				addToArrayForBgChanges(moduleToBgChange);
			}
		});
	}
	return (
		<>
			 {!isIntroRendered && <Intro introTextData={introTextData} introAnimationData={introAnimationData} />}
			<SEO globalSeo={globalSeoData} seoData={seoHomeData} />
			<HomeSlider data={homeSliderModule} globalButtons={globalSliderHomeButtons} activeSite="de" />
			<ExtendedContent data={allModules} activeSite="de" />
			{arrayForBgChanges.length > 0 && <BackgroundChange arrayForBgChanges={arrayForBgChanges} />}
		</>
	);
};

export default connect(
	(state) => ({
		isIntroRendered: state.app.isIntroRendered,
	}),
	null,
)(IndexPage);

export const query = graphql`
	query($remoteId: ID) {
		homepageData: allCraftStronaGlownaStronaGlownaEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: "de" } }) {
			nodes {
				slug
				id: remoteId
				language
				introTekst {
					url
				}
				introAnimacja {
					url
				}
				wybierzModul {
					...modulOpis
					...modulZdjecieZOpisem
					...modulNapiszDoNas
					...modulWizytowkiKategoriiProduktow
					...modulProcesPracy
					...modulAktualnosci
					__typename
					typeHandle
				}

				modulHomeSlider {
					... on Craft_modulHomeSlider_slajd_BlockType {
						zdjeciePoziome {
							url
						}
						zdjeciePionowe {
							url
						}
						tekst
						przyciskLink {
							...pojedynczePodstronyUri
							...kategoriaProduktowUri
							...aktualnosciUri
						}
						przyciskTekst
					}
				}
			}
		}

		homeOtherLang: allCraftStronaGlownaStronaGlownaEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: "de" } }) {
			nodes {
				slug
				id: remoteId
			}
		}

		seoHome: allCraftStronaGlownaStronaGlownaEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: "de" } }) {
			nodes {
				...seoHome
			}
		}

		globalSliderHome: allCraftSliderHomeGlobalSet(filter: { language: { eq: "de" } }) {
			nodes {
				sliderDalej
				sliderCofnij
			}
		}

		globalSeo: allCraftSeoGlobalSet(filter: { language: { eq: "de" } }) {
			nodes {
				seoTitle
				seoDescription
				seoKeywordsSource
				facebookOpengraphTitle
				facebookOpengraphDescription
				facebookOpengraphImage {
					url
				}
				facebookOpengraphImageDescription
				twitterTitle
				twitterDescription
				twitterImage {
					url
				}
				twitterImageDescription
			}
		}
		globalLogo: allCraftLogoGlobalSet(filter: { language: { eq: "de" } }) {
			nodes {
				logoAnimacja {
					url
				}
			}
		}
		globalCookies: allCraftCookiesGlobalSet(filter: { language: { eq: "de" } }) {
			nodes {
				cookiesIkona {
					url
				}
				cookiesTekst
				cookiesNapisNaPrzycisku
			}
		}
		footer: allCraftStopkaGlobalSet(filter: { language: { eq: "de" } }) {
			nodes {
				... on Craft_stopka_GlobalSet {
					stopka {
						... on Craft_stopka_lewaKolumna_BlockType {
							tekst
						}
						... on Craft_stopka_prawaKolumna_BlockType {
							ikona {
								url
							}
							email
							nazwaFirmy
							adres
						}
						... on Craft_stopka_dolnaNawigacja_BlockType {
							wybierzPodstrony {
								slug
								title
							}
						}
						... on Craft_stopka_logoPleo_BlockType {
							linkDoStrony
							logo {
								url
							}
						}
						__typename
					}
				}
			}
		}

		menuModuleData: allCraftMenuMenuEntry(filter: { language: { eq: "de" } }) {
			nodes {
				nazwaPodstrony
				opisPodstrony
				level
				remoteChildren {
					slug
					title
				}
				wybierzPodstrone {
					slug
					uri
				}
			}
		}

		submenuModuleData: allCraftSubmenuSubmenuEntry(filter: { language: { eq: "de" } }) {
			nodes {
				nazwaPodstrony
				wybierzPodstrone {
					slug
					uri
				}
			}
		}

		socialMediaModuleData: allCraftSocialMediaGlobalSet(filter: { language: { eq: "de" } }) {
			nodes {
				socialMedia {
					... on Craft_socialMedia_socialMedia_BlockType {
						linkUrl
						nazwa
						logo {
							url
						}
						logoZielone {
							url
						}
						widoczneWStopce
						widoczneWMenu
					}
				}
			}
		}
	}
`;
